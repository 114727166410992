import axios from 'axios';
import { Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth.js';
import md5 from 'js-md5'

const service = axios.create({
    baseURL: process.env.VUE_APP_AI_API,
    timeout: 200000,
});
service.interceptors.request.use(
    config => {
        let myTimestamp = Date.now()
        config.headers['platform'] = 'design_library'
        config.headers['timestamp'] = myTimestamp
        config.headers['signature'] = md5(`!@&yak5e@#ewe$qtngitimestamp=${myTimestamp}&platform=design_library`)
        if (getToken()) {
            config.headers['token'] = getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data;
        return res

    },
    error => {
        if (error.response.data.code == 301 && getToken()) {
            Message({
                // message: '登录已过期,请重新登录',
                message: '请先登录',
                type: 'error',
                duration: 6000
            })
            removeToken()
        } else {
            Message({
                message: error.message || 'Error',
                type: 'error',
                duration: 4000
            })
            return Promise.resolve(error.response);
        }

    }
);

export default service  