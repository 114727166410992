// 文件大小
function formatSize(size) {
    if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
    } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
    } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
    } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
    }
    if (size) {
        return size.toString() + " B";
    }
    return "";
}

// 时间戳转日期
export function timestampToTime(timestampLength, type) {
    var date
    if (!timestampLength) {
        date = new Date()
    } else {
        if (timestampLength.toString().length == 10) {
            date = new Date(timestampLength * 1000);
        } else {
            date = new Date(timestampLength)
        }
    }
    var Y = date.getFullYear();
    var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    switch (type) {
        case 1:
            return `${Y}.${M}.${D}`
        default:
            return `${Y}-${M}-${D} ${h}:${m}:${s}`
    }
}


export default { formatSize, timestampToTime }