<template>
  <div class="draw_box">
    <div class="draw_main">
      <div class="draw_item">
        <h3>图片上传</h3>
        <ai-upload @successUpload="getUploadImg" :tryList="tryList" />
      </div>
      <div class="draw_item">
        <h3>修复效果</h3>
        <div class="draw_result">
          <progress-loading :progress="loadingProgress" />
          <div class="bipartite_bg">
            <preview-img :previewImg="generateImg" :dName="toolTitle" />
          </div>
        </div>
      </div>
    </div>
    <div class="draw_btn">
      <div class="generate_btn ta3" @click="aiGenerate">
        <i class="iconfont icon-generate"></i>
        立即修复
      </div>
    </div>
  </div>
</template>

<script>
import aiUpload from "@/components/aiUpload.vue";
import previewImg from "@/components/previewImg.vue";
import progressLoading from "@/components/progressLoading.vue";
import { useGenerate } from "@/utils/useGenerate";

export default {
  name: "Repair",
  components: { aiUpload, progressLoading, previewImg },
  data() {
    return {
      toolTitle: "AI照片修复",
      tryList: [
        require(`@/assets/images/aiAsst/common/tryout_img9.png`),
        require(`@/assets/images/aiAsst/common/tryout_img10.png`),
        require(`@/assets/images/aiAsst/common/tryout_img3.png`),
        require(`@/assets/images/aiAsst/common/tryout_img4.png`),
      ], // 试用图片
      baseImg: "", // 上传底图

      // ai生成
      drawGenerate: "",
      requestState: "",
      loadingProgress: -1,
      generateImg: "",
    };
  },
  mounted() {
    useGenerate(this);
  },
  methods: {
    // 获取上传图片
    getUploadImg(val) {
      this.baseImg = val;
    },

    // ai生成
    aiGenerate() {
      let obj = {
        style: "repair", // 风格 3d clay黏土  paster贴纸  repair修复 rembg 去背景 color上色  enlarge放大
        action: "generate",
        image_base: this.baseImg,
        model: "llmf",
      };
      this.drawGenerate(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
