<template>
  <div class="creation_record">
    <div class="rec_box">
      <div class="title_box">
        <h2>创作记录</h2>
        <div class="batch_del">
          <div class="btn" v-show="batchDel" @click="cancelBatch()">取消</div>
          <div class="btn del" @click="confirmBatch">批量删除</div>
        </div>
      </div>
      <div class="scroll_wrapper" ref="scrollWrapper">
        <div class="waterfall_box">
          <Waterfall
            ref="waterfall"
            :list="recordList"
            rowKey="id"
            imgSelector="image_url"
            class="rec_waterfall"
            :width="250"
            :hasAroundGutter="false"
            :gutter="20"
            :animationDuration="300"
            :crossOrigin="false"
            backgroundColor="transparent"
            animationEffect="animate__backInUp"
            @afterRender="afterRender"
          >
            <template #item="{ item, url, index }">
              <div
                class="card"
                @click="openRecord(item)"
                v-if="item.status != 1"
              >
                <div class="card_img">
                  <LazyImg
                    :url="url"
                    @load="imgLoad"
                    @success="accImg(item)"
                    @error="accImg(item)"
                  />
                  <transition name="el-fade-in-linear">
                    <div
                      class="card_img_loading img_loading"
                      v-show="item.loading"
                    >
                      <i class="iconfont icon-loading"></i>
                      图片加载中...
                    </div>
                  </transition>
                </div>
                <div class="card_item" v-show="!item.loading">
                  <div class="item_del ta3" @click.stop="delSingle(item.id)">
                    <i class="iconfont icon-delete2"></i>
                  </div>
                </div>
                <transition name="el-fade-in-linear">
                  <div
                    class="del_box ta3"
                    :class="{ open_del: item.isdel }"
                    v-show="batchDel"
                    @click.stop="addDel(item, index)"
                  >
                    <div class="del_select ta3">
                      <i class="iconfont icon-select ta3"></i>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="card card_execute" v-else>
                <div class="waterfall_loading">
                  <i v-for="(e, i) in 12" :key="i"></i>
                </div>
                <p>图片生成中</p>
                <div class="execute_tips">
                  预计花费30-60s时间，可离开后再<br />回来查看效果喔~
                </div>
              </div>
            </template>
          </Waterfall>
          <transition name="el-fade-in-linear">
            <div class="waterfall_loading" v-show="loading">
              <i v-for="(e, i) in 12" :key="i"></i>
            </div>
          </transition>
          <div
            class="waterfall_finished animate__animated animate__bounceInLeft"
            v-show="finished"
          >
            没有更多数据了~
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="detState"
      append-to-body
      :show-close="false"
      width="500"
      class="rec_det_dialog"
    >
      <i class="iconfont icon-close det_close ta3" @click="closeRecord"></i>
      <div
        class="prev arrow_btn ta3"
        @click="openRecord(detData, 'prev')"
        v-if="detData.is_prev"
      >
        <i class="iconfont icon-arrow3"></i>
      </div>
      <div class="det_left">
        <swiper
          :space-between="18"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide>
            <el-image
              class="det_img"
              :src="detData.image_url"
              :preview-src-list="[detData.image_url]"
              preview-teleported
              fit="contain"
            >
              <template #placeholder>
                <div class="img_loading">
                  <i class="iconfont icon-loading"></i>
                  加载图片中...
                </div>
              </template>
            </el-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="det_right">
        <div class="det_btn_box">
          <div
            class="det_btn"
            @click="$downloadImage(detData.image_url, '创作记录')"
          >
            下载
          </div>
          <div class="det_btn del" @click="delSingle(detData.id, true)">
            删除
          </div>
        </div>
        <div class="det_data">
          <div class="det_type">
            <div class="det_title">生成工具</div>
            <div class="det_type_text det_bg">
              {{ detData.tool_name }}
            </div>
          </div>
          <div class="det_pro">
            <div class="det_title">用户输入</div>
            <div v-if="detData.prompt">
              <div class="prompt det_bg">
                {{ detData.prompt }}
              </div>
              <el-image
                class="prompt_img2"
                :src="detData.image_base"
                fit="contain"
                v-if="detData.image_base"
              ></el-image>
            </div>

            <div class="prompt_img det_bg" v-else>
              <el-image :src="detData.image_base" fit="contain"></el-image>
            </div>
          </div>
        </div>
      </div>
      <div
        class="next arrow_btn ta3"
        @click="openRecord(detData, 'next')"
        v-if="detData.is_next"
      >
        <i class="iconfont icon-arrow3"></i>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRecords, drawDetail, delImgs } from "@/api/creation";
import { LazyImg, Waterfall } from "vue-waterfall-plugin";
import "vue-waterfall-plugin/dist/style.css";
import BScroll from "@better-scroll/core";
import ScrollBar from "@better-scroll/scroll-bar"; // 为了修改滚动条
import MouseWheel from "@better-scroll/mouse-wheel"; // 为了触发鼠标滚动
import Pullup from "@better-scroll/pull-up"; // 为了加载
export default {
  name: "Record",
  components: { Waterfall, LazyImg },
  data() {
    return {
      sw: undefined,
      recordList: [], // 记录列表
      currentPage: 0, // 当前页数
      pageSize: 15, // 页码大小
      imgTimer: undefined,
      loading: false, // 列表加载
      finished: false, // 页面结束加载
      detState: false, // 详情弹框状态
      detData: {}, // 详情参数
      recordIds: [], // 删除的id数组
      batchDel: false, // 批量删除状态
    };
  },
  created() {
    BScroll.use(MouseWheel);
    BScroll.use(ScrollBar);
    BScroll.use(Pullup);
    this.getRecordList();
  },
  mounted() {
    this.sw = new BScroll(".scroll_wrapper", {
      scrollbar: {
        fade: false, // 始终显示滚动条
        interactive: true, // 滚动条是否可以交互
        scrollbarTrackClickable: true, // 滚动条轨道是否允许点击
      },
      mouseWheel: true, // 开启PC的鼠标滚动
      pullUpLoad: true, // 开启下拉加载
    });
    // 滚动条触底时
    this.sw.on("pullingUp", () => {
      this.getRecordList();
      this.sw.finishPullUp();
    });
  },
  methods: {
    // 获取记录列表
    getRecordList() {
      if (this.finished || this.loading) return;
      this.loading = true;
      this.currentPage++;
      getRecords({
        status: -1, //   -1-全部 0-待处理 1-进行中 2-失败 3-成功
        page_no: this.currentPage,
        page_size: this.pageSize,
      }).then((res) => {
        if (!res.data.lists.length) this.over();
        res.data.lists.forEach((e, i) => {
          e.loading = true;
          if (i + 1 == res.data.lists.length) {
            this.recordList = this.recordList.concat(res.data.lists);
            if (res.data.lists.length < this.pageSize) this.over();
          }
        });
      });
    },

    // 瀑布流结束
    over() {
      this.finished = true;
      this.loading = false;
    },

    // 瀑布流卡片坐标计算完成并且移动到了对应位置（列表渲染的过程可能会多次触发，比如有一张图片加载完成就会重新计算位置）
    afterRender() {
      if (this.imgTimer) clearTimeout(this.imgTimer);
      this.imgTimer = setTimeout(() => {
        this.loading = false;
        this.sw.refresh();
        // 瀑布流不足滚动时
        if (
          this.$refs.scrollWrapper.clientHeight >
          this.$refs.waterfall.$el.clientHeight
        ) {
          this.getRecordList();
        }
      }, 200);
    },

    // 图片加载
    imgLoad() {
      // 瀑布流重绘
      if (this.imgTimer) clearTimeout(this.imgTimer);
      this.imgTimer = setTimeout(() => {
        this.$refs.waterfall.renderer();
      }, 200);
      // this.afterRecord();
    },

    // 图片加载完成
    accImg(item) {
      item.loading = false;
    },

    // 绘画详情-打开
    openRecord(item, action) {
      let obj = {
        records_id: [item.id],
      };
      if (action) obj.action = action;
      drawDetail(obj).then((res) => {
        this.detData = res.data[0];
        this.detState = true;
      });
    },

    // 绘画详情-关闭
    closeRecord() {
      this.detState = false;
      this.detData = {};
    },

    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange(swiper) {
      console.log(swiper);
    },

    // 瀑布流显示删除
    recordArrDel() {
      this.recordList = this.recordList.filter(
        (e) => !this.recordIds.includes(e.id)
      );
      this.cancelBatch(true);
    },

    // 删除记录
    delRecord(remind, isDet = false) {
      this.$confirm(remind, "删除提醒", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        customClass: "batch_del_warn",
        showClose: false,
      })
        .then(() => {
          delImgs({ ids: this.recordIds }).then((res) => {
            this.recordArrDel();
            this.$message({
              message: "删除成功",
              type: "success",
            });
            if (isDet) {
              this.detState = false;
            }
          });
        })
        .catch(() => {
          this.cancelBatch();
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 单个删除
    delSingle(id, isDet) {
      this.recordIds.push(id);
      this.delRecord("确定删除当前图片吗?", isDet);
    },

    // 批量删除
    confirmBatch() {
      if (!this.batchDel) {
        this.batchDel = true;
      } else {
        if (this.recordIds.length) {
          this.delRecord(`确认删除${this.recordIds.length}张图片吗？`);
        } else {
          this.$message({
            message: "请先选择需要删除的图片",
            type: "warning",
          });
        }
      }
    },

    // 批量删除-添加
    addDel(item, i) {
      item.isdel = !item.isdel || false;
      if (item.isdel) {
        this.recordIds.push(item.id);
      } else {
        let index = this.recordIds.indexOf(item.id);
        this.recordIds.splice(index, 1);
      }
      this.$set(this.recordList, i, this.recordList[i]);
    },

    // 批量删除-取消
    cancelBatch(empty = false) {
      this.batchDel = false;
      if (!empty && this.recordIds.length) {
        this.recordList = this.recordList.filter((e) => {
          if (this.recordIds.includes(e.id)) {
            e.isdel = false;
          }
          return true;
        });
      }
      this.recordIds = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.creation_record {
  width: 100%;
  height: 100%;
  background: url("@/assets/images/aiAsst/gradual_bg.png") no-repeat #f5f8fb;
  background-size: 100% auto;
  position: relative;
  display: flex;
  justify-content: center;

  .rec_box {
    max-width: 1600px;
    width: 100%;
    margin: 40px 20px 0;

    .title_box {
      margin: 0 auto 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-family: "pmzdFont";
        font-size: 28px;
        color: #25c894;
        margin-left: 11px;
      }

      .batch_del {
        display: flex;
        align-items: center;
        gap: 10px;

        .btn {
          width: 100px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          justify-content: center;
          color: #333;
          font-size: 14px;
          background: #cdcdcd;
          border-radius: 5px;
          cursor: pointer;
        }

        .del {
          background: #25c894;
          color: #fff;
        }
      }
    }

    .scroll_wrapper {
      width: 100%;
      height: calc(100vh - 174px);
      overflow: hidden;

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      .waterfall_box {
        text-align: center;
        padding-bottom: 70px;

        .rec_waterfall {
          margin: 0 auto;
          text-align: left;

          .card {
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;

            .card_img {
              width: 100%;
              position: relative;
              // img {
              //   width: 100%;
              //   height: 100%;
              //   object-fit: cover;
              // }
              .card_img_loading {
                position: absolute;
                top: 0;
                left: 0;
                min-height: 250px;
              }
            }

            .card_item {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;

              .item_del {
                position: absolute;
                top: -35px;
                right: -35px;
                width: 30px;
                height: 30px;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 4px;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
                color: #fff;

                &:hover {
                  color: #25c894;
                }
              }

              &:hover {
                .item_del {
                  top: 8px;
                  right: 8px;
                }
              }
            }

            .del_box {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;

              .del_select {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                overflow: hidden;
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                &::before {
                  content: "";
                  width: 95%;
                  height: 95%;
                  border-radius: 50%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  border: 2px solid #fff;
                }

                i {
                  position: absolute;
                  bottom: -30px;
                  right: -30px;
                  transform: translate(50%, 50%);
                  font-size: 26px;
                  color: #f47474;
                }
              }
            }

            .open_del {
              background: rgba(0, 0, 0, 0.3);
              overflow: auto;

              .del_select {
                border-color: #f47474;

                i {
                  bottom: 50%;
                  right: 50%;
                }
              }
            }
          }

          .card_execute {
            width: 100%;
            height: 336px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #333;

            .waterfall_loading {
              color: #333;
            }

            p {
              font-size: 14px;
              font-weight: bold;
              margin: 5px 0 8px;
            }

            .execute_tips {
              font-size: 12px;
              line-height: 14px;
              text-align: center;
            }
          }
        }

        .waterfall_loading {
          width: 35px;
          height: 35px;
          box-sizing: border-box;
          animation: loading-rotate 0.8s linear infinite;
          animation-timing-function: steps(12);
          position: relative;
          display: inline-block;
          color: #25c894;

          i {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;

            &::before {
              width: 2px;
              height: 25%;
              content: " ";
              display: block;
              margin: 0 auto;
              border-radius: 40%;
              background-color: currentColor;
            }
          }
        }

        .waterfall_finished {
          margin: 20px 0;
          color: #25c894;
        }
      }
    }
  }
}

@keyframes loading-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@for $i from 1 through 12 {
  .waterfall_loading i:nth-of-type(#{$i}) {
    opacity: calc(1 - (1.1 / 12) * (#{$i} - 1));
    transform: rotate(calc(#{$i} * 30deg));
  }
}
</style>
<style lang="scss" >
.rec_det_dialog {
  .el-dialog {
    width: 738px;
    border-radius: 20px;
    padding: 0;
    .el-dialog__header {
      padding: 0;
      &::after {
        display: none;
      }
    }
    .el-dialog__body {
      height: 600px;
      padding: 30px;
      display: flex;
      gap: 30px;
      position: relative;

      .det_close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #333;
        cursor: pointer;

        &:hover {
          color: #25c894;
        }
      }

      .arrow_btn {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        background: rgba(255, 255, 255, 0.7);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        i {
          font-size: 24px;
        }

        &:hover {
          background: rgba(255, 255, 255, 1);
        }
      }

      .prev {
        left: -62px;

        i {
          margin-right: 2px;
        }
      }

      .next {
        right: -62px;

        i {
          margin-left: 2px;
          transform: rotate(180deg);
        }
      }

      .det_left {
        width: 306px;

        .det_img {
          width: 100%;
          border-radius: 10px;
          .img_loading {
            min-height: 540px;
          }
        }
      }

      .det_right {
        flex: 1;
        max-height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        .det_btn_box {
          display: flex;
          gap: 10px;

          .det_btn {
            width: 88px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            border-radius: 10px;
            background: #25c894;
            cursor: pointer;
          }

          .del {
            background: #f05959;
          }
        }

        .det_data {
          margin-top: 20px;
          color: #333;

          .det_title {
            font-size: 14px;
            margin-bottom: 10px;
          }

          .det_bg {
            padding: 10px 15px;
            border-radius: 10px;
            background: #f2f2f2;
          }

          .det_type {
            margin-bottom: 20px;

            .det_type_text {
              width: 100%;
              font-size: 12px;
            }
          }

          .det_pro {
            .prompt {
              font-size: 12px;
              line-height: 14px;
            }

            .prompt_img {
              .el-image {
                width: 126px;
                border-radius: 4px;
              }
            }

            .prompt_img2 {
              width: 80px;
              border-radius: 4px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.batch_del_warn {
  width: 400px;
  height: auto;
  padding: 34px 45px;
  box-sizing: border-box;
  border-radius: 20px;

  .el-message-box__header {
    padding: 0;

    .el-message-box__title {
      color: #333;
    }
  }

  .el-message-box__content {
    padding: 20px 0;

    .el-message-box__container {
      .el-message-box__message {
        color: #333;
      }
    }
  }

  .el-message-box__btns {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .el-button {
      width: 150px;
      height: 48px;
      margin: 0;
      background: #e7e7e7;
      border-radius: 6px;
      color: #000;
      font-size: 14px;
      border: none;
    }

    .el-button--primary {
      background: #25c894;
      color: #fff;
    }
  }
}
</style>
