<template>
  <transition name="el-zoom-in-bottom">
    <div class="p_loading" v-if="progress != -1">
      <div class="p_loading_box">
        <el-progress
          type="circle"
          :percentage="progress"
          color="#25c894"
          :width="90"
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ProgressLoading",
  props: {
    progress: {
      default: "",
      type: String | Number,
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.p_loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  .p_loading_box {
    width: 130px;
    height: 130px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .el-progress {
      .el-progress__text {
        color: #fff;
        font-size: 18px !important;
      }
    }
  }
}
</style>
