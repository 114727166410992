<template>
  <div class="creation">
    <div class="ai_module">
      <h2>AI创作</h2>
      <ul class="ai_creation_list">
        <li
          class="ai_creation_item"
          v-for="(e, i) in toolkitList"
          :key="i"
          @click="goTool(e.type)"
        >
          <div class="creation_img">
            <img
              :src="
                require(`@/assets/images/aiAsst/creation/ai_img${i + 1}.png`)
              "
              alt=""
              class="ta3"
            />
          </div>
          <div class="creation_bottom">
            <div class="creation_info ta3">
              <p class="creation_name">{{ e.name }}</p>
              <div class="creation_introduce toh">{{ e.introduce }}</div>
            </div>
            <div class="creation_btn ta3">去使用</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    goTool(type) {
      this.$router.push(`/ai/toolkit/${type}`);
    },
  },
  computed: {
    ...mapGetters(["toolkitList"]),
  },
};
</script>

<style lang="scss" scoped>
.creation {
  width: 100%;
  height: 100%;
  background: url("@/assets/images/aiAsst/gradual_bg.png") no-repeat #f5f8fb;
  background-size: 100% auto;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 40px;

  .ai_module {
    width: 100%;
    max-width: 1600px;

    h2 {
      font-family: "pmzdFont";
      font-size: 28px;
      color: #25c894;
      margin-bottom: 20px;
    }

    .ai_creation_list {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      .ai_creation_item {
        width: 310px;
        border-radius: 12px;
        overflow: hidden;
        background: #fff;
        cursor: pointer;

        &:hover {
          .creation_img {
            img {
              transform: scale(1.1);
            }
          }

          .creation_bottom {
            .creation_info {
              width: 166px;
            }

            .creation_btn {
              opacity: 1;
            }
          }
        }

        .creation_img {
          width: 100%;
          height: 180px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .creation_bottom {
          display: flex;
          align-items: center;
          gap: 13px;
          padding: 12px 15px;
          width: 100%;

          .creation_info {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex-shrink: 0;

            .creation_name {
              font-size: 18px;
              color: #333;
              line-height: 24px;
            }

            .creation_introduce {
              font-size: 14px;
              color: #8f92a9;
              line-height: 18px;
            }
          }

          .creation_btn {
            width: 100px;
            height: 38px;
            background: #25c894;
            border-radius: 40px;
            font-size: 14px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            flex-shrink: 0;

            &:hover {
              background: #1adc8a;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .creation {
    .ai_module {
      max-width: 1280px;
    }
  }
}
</style>
