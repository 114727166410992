<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("org/getOrgInfo");
    if (getToken()) {
      this.$store.dispatch("user/getInfo");
    }
    //在页面加载时读取sessionStorage里的状态信息并删除
    if (sessionStorage.getItem("vuex")) {
      this.$store.replaceState(
        Object.assign(
          this.$store.state,
          JSON.parse(sessionStorage.getItem("vuex")),
          sessionStorage.removeItem("vuex")
        )
      );
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("vuex", JSON.stringify(this.$store.state));
    });
  },
  methods: {},
};
</script>


<style lang="scss">
@import "@/assets/icon/iconfont/iconfont.css";
@import "@/assets/icon/iconfont2/iconfont.css";
@import url("./assets/style/iconfont.css");
@import "@/assets/font/pmzd.css";
</style>
