<template>
  <div class="ai_upload_box">
    <el-upload
      v-show="!uploadImg"
      ref="aiUpload"
      class="ai_upload"
      drag
      multiple
      :action="actionUrl"
      :accept="acceptType"
      :show-file-list="false"
      :limit="uploadLimit"
      :before-upload="beforeUpload"
      :on-exceed="handleExceed"
      :on-success="onSuccess"
      :on-progress="onProgress"
    >
      <div class="upload_btn">上传图片</div>
      <p class="upload_tips">点击或将照片拖拽到这里上传</p>

      <div class="extension_box upload_text">
        支持扩展名：
        <span v-for="(e, i) in acceptType.split('.')" :key="i">{{
          e.replace(",", "、")
        }}</span>
      </div>
      <div class="upload_text">{{ uploadtext }}</div>

      <div class="try_box" v-if="tryList.length">
        <el-divider> 试试这张 </el-divider>
        <div class="try_img">
          <img
            :src="isText ? e.img : e"
            alt=""
            class="try_item"
            v-for="(e, i) in tryList"
            :key="i"
            @click.stop="getImg(e)"
          />
        </div>
      </div>
    </el-upload>
    <transition name="el-zoom-in-center">
      <div class="loding_box" v-show="uploadLoading">
        <div class="loding_icon">
          <i class="el-icon-top"></i>
        </div>
        <div class="progress_text">上传进度: {{ uploadPercent }} %</div>
      </div>
    </transition>
    <div class="img_box" v-show="uploadImg">
      <el-image
        class="upload_img"
        :src="uploadImg"
        :preview-src-list="[uploadImg]"
        fit="contain"
      >
        <template #placeholder>
          <div class="img_loading">
            <i class="iconfont icon-loading"></i>
            加载图片中...
          </div>
        </template>
      </el-image>
      <div class="preview_btn ta3" @click="reUpload">
        <i class="iconfont icon-close"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AiUpload",
  props: {
    // 上传api
    actionUrl: {
      default: `${process.env.VUE_APP_UPLOAD_IMG_URL}/api/upload`,
      type: String,
    },
    // 允许上传文件
    acceptType: {
      default: ".jpeg,.jpg,.png",
      type: String,
    },
    // 上传文字提示
    uploadtext: {
      default: "每张图片大小限制5M以内，尺寸最长边小于2000*2000px",
      type: String,
    },
    // 最大上传大小(单位M)
    maxFileSize: {
      default: 5,
      type: Number,
    },
    // 上传数量
    uploadLimit: {
      default: 1,
      type: Number,
    },
    // 试用数组
    tryList: {
      default: () => [],
      type: Array,
    },
    // 试用参数类型
    isText: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      uploadLoading: false,
      uploadPercent: 0,
      uploadImg: "",
    };
  },
  mounted() {},
  methods: {
    // 上传前验证
    beforeUpload(rawFile) {
      let allowArr = this.acceptType.replaceAll(".", "image/").split(",");

      if (allowArr.indexOf(rawFile.type) == -1) {
        this.$message.error(`暂只支持上传${this.acceptType}文件`);
        return false;
      } else if (rawFile.size / 1024 / 1024 > this.maxFileSize) {
        this.$message.error(`图片大小需不超过${this.maxFileSize}M`);
        return false;
      }
      return true;
    },

    // 上传超过限制
    handleExceed(files) {
      if (this.uploadLimit == 1) {
        this.$refs.aiUpload.clearFiles();
        // this.$refs.aiUpload.submit();
      } else {
        this.$message.error(`只能上传${this.uploadLimit}个文件`);
      }
    },

    // 上传中
    onProgress(progressEvent) {
      this.uploadLoading = true;
      this.uploadPercent = progressEvent.percent.toFixed(0);
    },

    // 上传成功
    onSuccess(res, file) {
      this.uploadLoading = false;
      this.uploadPercent = 0;
      this.getImg(this.$prefixSrc(res.data.path));
    },

    // 重新上传
    reUpload() {
      this.getImg("");
      this.$refs.aiUpload.clearFiles();
    },

    // 获取图片
    getImg(val) {
      let myVal = val;
      if (val.indexOf("http") == -1) myVal = location.origin + val;
      this.uploadImg = val;
      this.$emit("successUpload", myVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.ai_upload_box {
  position: relative;
  height: 62vh;
  padding: 2%;
  min-width: 540px;
  min-height: 452px;
  background: #fff;
  border-radius: 10px;

  .ai_upload {
    width: 100%;
    height: 100%;

    ::v-deep .el-upload {
      width: 100%;
      height: 100%;

      .el-upload-dragger {
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #e3e6ec;
        transition: all 0.3s;

        .upload_btn {
          width: 104px;
          height: 41px;
          line-height: 41px;
          text-align: center;
          background: #25c894;
          border-radius: 4px 4px 4px 4px;
          font-size: 16px;
          color: #fff;
        }

        .upload_tips {
          margin: 8px 0;
          font-size: 16px;
          color: #25c894;
          line-height: 19px;
        }

        .upload_text {
          font-size: 14px;
          color: #777;
        }

        .extension_box {
          line-height: 18px;
          margin-bottom: 3px;
        }

        .try_box {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .el-divider {
            width: 92%;

            .el-divider__text {
              transition: all 0.3s;
            }
          }

          .try_img {
            display: flex;
            gap: 14px;

            .try_item {
              width: 100px;
              height: 100px;
              border-radius: 4px;
              object-fit: cover;
            }
          }
        }

        &:hover {
          background: #f7faff;

          .try_box {
            .el-divider {
              .el-divider__text {
                background: #f7faff;
              }
            }
          }
        }
      }

      .el-upload-dragger.is-dragover {
        background: #f7faff;
      }
    }
  }

  .loding_box {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    z-index: 100;

    .loding_icon {
      height: 80px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 60px;
        font-weight: bold;
        animation: topTranslate 2s infinite;
      }
    }
  }

  .img_box {
    width: 100%;
    height: 100%;
    background: #fff;
    border: 1px solid #e3e6ec;
    position: relative;

    .upload_img {
      width: 100%;
      height: 100%;
    }

    .preview_btn {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ddd;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;

      i {
        font-size: 14px;
      }

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      .re_upload_box {
        opacity: 1;
      }
    }
  }
}
</style>
