<template>
  <div
    id="libHeader"
    :class="type == 1 ? 'header' : 'header style2'"
    :style="isFixed ? 'position: fixed;' : ''"
  >
    <div class="welcome_father" v-if="showOrg">
      <div class="welcome">
        {{ org_name ? `${org_name}欢迎您!` : "翼狐设计学习库欢迎您!" }}
      </div>
    </div>
    <div class="header_main">
      <router-link to="/" class="dib line-height1 fl logo-box">
        <img src="../assets/images/header/logo2.png" alt="" class="ico" />
      </router-link>
      <ul class="clearfix nav-box dib">
        <li>
          <router-link
            to="/"
            class="nav_item"
            :class="active == 1 ? 'active' : ''"
            >首页</router-link
          >
        </li>
        <li>
          <a href="https://lib.yiihuu.com/list/" class="nav_item">视频教程</a>
        </li>
        <li class="pr" v-if="modulePer.lib_learn_status">
          <span>
            学习路径 <i class="el-icon-arrow-down"></i>
            <div class="sub-nav">
              <router-link to="/learn/myPath"> 学习路径</router-link>
              <router-link to="/learn/subject"> 学习专题</router-link>
            </div>
          </span>
        </li>
        <li><a href="https://lib.yiihuu.com/live/" class="live-link"></a></li>
        <li class="pr" v-if="modulePer.lib_sc_status">
          <span @click="whetherShowPer(1, $event)">
            设计中心 <i class="el-icon-arrow-down"></i>
            <div class="sub-nav">
              <a href="https://lib.yiihuu.com/sc/">在线设计</a>
              <router-link
                :to="showPer ? { path: $route.fullPath } : '/design/matting'"
              >
                在线抠图</router-link
              >
              <router-link
                :to="showPer ? { path: $route.fullPath } : '/design/picture'"
              >
                图片编辑</router-link
              >
            </div>
          </span>
        </li>
        <li class="pr">
          <span>
            比赛活动 <i class="el-icon-arrow-down"></i>
            <div class="sub-nav">
              <!-- <a href="https://lib.yiihuu.com/activity/">创作活动</a> -->
              <router-link to="/activity">创作活动</router-link>
              <router-link to="/charts">学习排行</router-link>
            </div>
          </span>
        </li>
        <li v-show="smallScreen">
          <a href="#" class="nav_item" @click="openAiSeek"> AI找课 </a>
        </li>
        <div class="li_box" v-show="!smallScreen">
          <li>
            <router-link
              to="/resource"
              class="nav_item"
              :class="active == 4 ? 'active' : ''"
              >资源下载</router-link
            >
          </li>
          <li class="pr">
            <span
              >数字展览 <i class="el-icon-arrow-down"></i>
              <div class="sub-nav">
                <router-link to="/works">作品广场</router-link>
                <a href="http://www.fd.show/">线上展览</a>
              </div>
            </span>
          </li>
          <li class="pr">
            <span>
              图文资讯 <i class="el-icon-arrow-down"></i>
              <div class="sub-nav">
                <a href="https://lib.yiihuu.com/twjc/">图文教程</a>
                <a href="https://lib.yiihuu.com/news/">行业资讯</a>
              </div>
            </span>
          </li>
          <li class="pr">
          <span>
            AI助手 <i class="el-icon-arrow-down"></i>
            <div class="sub-nav">
              <router-link to="/agent">智能应用</router-link>
              <router-link to="/ai/creation">AI创作</router-link>
            </div>
          </span>
        </li>
          <li>
            <a href="#" class="nav_item" @click="openAiSeek"> AI找课 </a>
          </li>
        </div>
        <div class="li_box small_screen ta3" v-show="smallScreen">
          <span>
            更多 <i class="el-icon-arrow-down more_arrow ta3"></i>
            <div class="small_more ta3">
              <router-link
                to="/resource"
                class="more_item dfJcsb"
                :class="active == 4 ? 'active' : ''"
                >资源下载</router-link
              >
              <div class="more_nav_box">
                <div class="more_nav dfJcsb">
                  数字展览 <i class="el-icon-arrow-down ta3"></i>
                </div>
                <div class="item_nav ta3 dfFw">
                  <router-link to="/works">作品广场</router-link>
                  <a href="http://www.fd.show/">线上展览</a>
                </div>
              </div>
              <a
                href="https://lib.yiihuu.com/guide.php"
                target="_blank"
                class="more_item dfJcsb"
              >
                设计导航
              </a>
              <div class="more_nav_box">
                <div class="more_nav dfJcsb">
                  图文资讯 <i class="el-icon-arrow-down ta3"></i>
                </div>
                <div class="item_nav ta3 dfFw">
                  <a href="https://lib.yiihuu.com/twjc/">图文教程</a>
                  <a href="https://lib.yiihuu.com/news/">行业资讯</a>
                </div>
              </div>
              <div class="more_nav_box">
                <div class="more_nav dfJcsb">
                  AI助手 <i class="el-icon-arrow-down ta3"></i>
                </div>
                <div class="item_nav ta3 dfFw">
                  <router-link to="/agent">智能应用</router-link>
                  <router-link to="/ai/creation">AI创作</router-link>
                </div>
              </div>
            </div>
          </span>
        </div>
      </ul>
      <div class="head_right">
        <div class="clearfix input">
          <!-- 只能使用form表单发请求的形式指定编码 -->
          <form
            method="get"
            action="https://lib.yiihuu.com/list/"
            accept-charset="gbk"
          >
            <div class="search_video">
              <input
                class="input1"
                type="text"
                name="q"
                placeholder="搜索视频"
                value=""
              />
              <input type="hidden" name="web_flag" value="yiihuu" />
              <div class="submit_box">
                <input type="submit" class="submit" value="" />
              </div>
            </div>
          </form>
        </div>
        <div class="login-box" v-if="token && nick">
          <a
            href="https://lib.yiihuu.com/personal/personal_info.php"
            class="nick toh"
            >{{ nick }}</a
          >
          <span>&nbsp;|&nbsp;</span>
          <a href="javascript:;" @click="logout">退出</a>
        </div>
        <div class="login-box" v-else>
          <a href="javascript:;" class="nick" @click="showLogin">登录</a>
          <span>&nbsp;|&nbsp;</span>
          <a @click="showRegister" href="javascript:;">注册</a>
        </div>
      </div>
    </div>
    <!-- 登录弹出框 -->
    <loginDialog ref="loginDialog"></loginDialog>
    <!-- 注册弹出框 -->
    <registerDialog ref="registerDialog"></registerDialog>

    <!-- 限制弹框 -->
    <permissionDialog
      ref="permissionDialog"
      :myModule="showPerType"
    ></permissionDialog>
    <div class="ai_seek_popup" v-if="aiSeek">
      <div class="wrap">
        <iframe
          data-v-788b06d9=""
          frameborder="0"
          width="100%"
          height="100%"
          src="https://www.yiihuu.com/aisearch/?platform=lib"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/api/home.js";
import { mapGetters } from "vuex";
import { getToken, getNick } from "@/utils/auth.js";
export default {
  name: "Header",
  components: {},
  props: {
    isFixed: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Number,
      default: 2,
    },
    active: {
      type: Number,
      default: 0,
    },
    showOrg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchData: "",
      token: getToken(),
      nick: getNick(),
      smallScreen: false,
      showPerType: 1,
      aiSeek: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 1500) {
      this.smallScreen = !this.smallScreen;
    }
    // 观察导航宽度变动
    const mediaQuery = window.matchMedia(
      "screen and (max-width: 1500px) and (min-width: 270px)"
    );
    mediaQuery.onchange = (e) => {
      this.smallScreen = mediaQuery.matches;
    };
  },
  methods: {
    whetherShowPer(type, e) {
      if (this.showPer) {
        this.showPerDialog(type, e);
      }
    },
    showPerDialog(type, e) {
      this.showPerType = type;
      if (e) {
        e.preventDefault();
      }
      this.$refs.permissionDialog.showPermissionDialog();
    },
    logout() {
      logout()
        .then((res) => {
          location.reload();
        })
        .catch((err) => {
          location.reload();
        });
    },
    showLogin() {
      this.$refs.loginDialog.showLoginDialog();
    },
    showRegister() {
      this.$refs.registerDialog.showRegisterDialog();
    },
    openAiSeek() {
      document.domain = "yiihuu.com";
      this.aiSeek = true;
    },

    // iframe层内调用
    closeAiSeek() {
      this.aiSeek = false;
    },
  },
  computed: {
    ...mapGetters(["org_name", "modulePer", "showPer"]),
  },
};
</script>

<style lang="scss" scoped>
.style2 {
  background: #fff !important;
  .header_main {
    .head_right {
      .login-box {
        a,
        span {
          color: #101010 !important;
        }
      }
      .input {
        border: 1px solid #22b79e;
        .search_video {
          .input1 {
            color: #101010 !important;
            &::placeholder {
              color: #999 !important;
            }
          }
          .submit_box {
            margin-top: -1px;
          }
        }
      }
    }
  }
  .nav-box {
    li {
      .nav_item {
        color: #101010;
      }
      .active {
        color: #25c894;
      }
      span {
        color: #101010;
        &:hover {
          .sub-nav {
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .small_screen {
      color: #101010;
    }
  }
}
input[type="hidden" i] {
  display: none !important;
}
.header {
  width: 100%;
  background: rgba(51, 51, 51, 0.39);
  top: 0;
  left: 0;
  z-index: 2500;
  box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.05);
  .welcome_father {
    width: 100%;
    background-color: #22b79e;
    .welcome {
      width: 1380px;
      margin: 0 auto;
      height: 31px;
      line-height: 30px;
      color: white;
      font-size: 14px;
    }
  }
  .header_main {
    width: 1380px;
    height: 76px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    .head_right {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      padding-top: 4px;
      .login-box {
        line-height: 76px;
        .nick {
          max-width: 60px;
        }
        a,
        span {
          color: #fff;
          font: 12px/1.5 "Microsoft YaHei", \5b8b\4f53;
          vertical-align: middle;
        }
      }
      .input {
        width: 170px;
        height: 36px;
        border-radius: 18px;
        background: rgba(243, 243, 243, 0.39);
        margin-top: 20px;
        margin-right: 10px;
        .search_video {
          height: 36px;
          position: relative;
          .input1 {
            float: left;
            border-radius: 16px;
            width: 100%;
            height: 100%;
            outline: none;
            padding: 10px 51px 8px 15px;
            background: none;
            border: none;
            box-sizing: border-box;
            color: #fff;
            &::placeholder {
              color: #fff;
            }
          }
          .submit_box {
            background: linear-gradient(90deg, #1adc8a 0%, #23b4a0 100%);
            position: absolute;
            right: -1px;
            width: 51px;
            height: 100%;
            border-radius: 26px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            .submit {
              background: url("../assets/images/header/search_icon2.png")
                no-repeat;
              width: 18px;
              height: 18px;
              border: none;
              cursor: pointer;
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
  .logo-box {
    margin: 14px 40px 0 0;
  }
}
.nav-box {
  li {
    float: left;
    font-size: 14px;
    line-height: 76px;
    height: 76px;
    margin-left: 18px;
    padding-top: 4px;
    box-sizing: border-box;
    &:first-child {
      margin-left: 0;
    }
    .nav_item {
      color: #fff;
      position: relative;
      transition: 0.3s;
      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 2px;
        background: linear-gradient(180deg, #1adc8a 0%, #23b4a0 100%);
        position: absolute;
        bottom: -10px;
        right: 0;
        left: auto;
        transition: 0.3s;
      }
      &:hover {
        color: #25c894;
        &::after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
    .active {
      color: #25c894;
      &::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background: linear-gradient(180deg, #1adc8a 0%, #23b4a0 100%);
        position: absolute;
        bottom: -10px;
        left: 0;
      }
    }
    span {
      color: #fff;
      cursor: pointer;
      height: 76px;
      display: inline-block;
      line-height: 75px;
      font-size: 14px;
      transition: 0.3s;
      i {
        transition: 0.3s;
      }
      &:hover {
        color: #25c894;
        .el-icon-arrow-down {
          color: #25c894;
          transform: rotate(180deg);
        }
        .sub-nav {
          height: auto;
          padding: 6px;
          a {
            height: 38px;
            margin-bottom: 4px;
          }
        }
      }
      .sub-nav {
        z-index: 9999;
        background-color: #fff;
        text-align: center;
        position: absolute;
        width: 100px;
        height: auto;
        left: 50%;
        top: 68px;
        transform: translate(-50%, 0);
        overflow: hidden;
        transition: 0.3s;
        border-radius: 5px;
        a {
          display: block;
          height: 0;
          line-height: 38px;
          background: #f0f0f0;
          border-radius: 5px;
          color: #333;
          transition: 0.3s;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            color: #fff;
            background: linear-gradient(180deg, #1adc8a 0%, #23b4a0 100%);
          }
        }
      }
    }
  }
  .li_box {
    display: inline-block;
    margin-left: 18px;
  }
  .small_screen {
    color: #fff;
    cursor: pointer;
    height: 76px;
    display: inline-block;
    line-height: 75px;
    font-size: 14px;
    padding-top: 4px;
    position: relative;
    &:hover {
      color: #25c894;
      .more_arrow {
        color: #25c894;
        transform: rotate(180deg);
      }
      .small_more {
        .more_item {
          height: 38px;
          padding: 0 20px;
          border-bottom: 1px solid #f4f4f4;
        }
        .more_nav_box {
          .more_nav {
            height: 38px;
            padding: 0 20px;
          }
        }
      }
    }
    .small_more {
      width: 226px;
      height: auto;
      position: absolute;
      background-color: #fff;
      left: 50%;
      top: 68px;
      transform: translate(-50%, 0);
      border-radius: 5px;
      z-index: 3;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
      line-height: 0;
      overflow: hidden;
      .more_item {
        width: 100%;
        height: 0;
        border-bottom: 0px solid #f4f4f4;
        transition: 0.3s;
        color: #333;
        line-height: 38px;
        overflow: hidden;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          color: #25c894;
        }
      }
      .more_nav_box {
        color: #333;
        height: auto;
        padding: 0;
        transition: 0.3s;
        .more_nav {
          width: 100%;
          height: 0;
          transition: 0.3s;
          line-height: 38px;
          overflow: hidden;
          i {
            line-height: 38px;
          }
        }
        .item_nav {
          width: 100%;
          height: auto;
          overflow: hidden;
          background: #fafafa;
          padding: 0;
          a {
            display: inline-block;
            width: 88px;
            height: 0px;
            line-height: 32px;
            text-align: center;
            background: #f0f0f0;
            border-radius: 5px;
            transition: 0.3s;
            overflow: hidden;
            &:hover {
              background: linear-gradient(180deg, #1adc8a 0%, #23b4a0 100%);
              color: #fff;
            }
          }
        }
        &:hover {
          .el-icon-arrow-down {
            transform: rotate(180deg);
          }
          .item_nav {
            padding: 10px 20px 0 20px;
            a {
              height: 32px;
              margin: 0 10px 10px 0;
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.nick {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1500px) and (min-width: 270px) {
  .header {
    .welcome_father {
      .welcome {
        width: 1100px;
      }
    }
    .header_main {
      width: 1100px;
      .head_right {
        .input {
          width: 150px;
        }
      }
    }
  }
}
.live-link {
  display: inline-block;
  position: relative;
  width: 58px;
  height: 100%;
  background: url("../assets/images/header/yiihuuLive.png") 0 15px no-repeat;
  background-size: 100%;
  &:hover {
    background: url("../assets/images/header/yiihuuLive-hover.png") 0 15px
      no-repeat;
    background-size: 100%;
  }
}

.ai_seek_popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1990;
  .wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>