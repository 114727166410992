<template>
  <el-container>
    <el-aside class="creation_aside">
      <div class="aside_main">
        <h2 class="tool_title">{{ toolTitle }}</h2>
        <ai-input
          :inputVal="drawingVal"
          :inputHint="drawingHint"
          @getVal="getDrawingVal"
          showExample
          exampleType="drawing"
        />

        <h2 class="tool_title mt20">比例</h2>
        <div class="ratio_box">
          <div
            class="ratio_item"
            :class="{ active: e.size == currentRatio }"
            v-for="(e, i) in ratioList"
            :key="i"
            @click="selectRatio(e.size)"
          >
            <div class="ratio_top ta3">
              <div class="ratio_image_box">
                <div
                  class="ratio_image ta3"
                  :style="{ width: e.width, height: e.height }"
                ></div>
              </div>
              <div class="ratio ta3">{{ e.ratio }}</div>
            </div>
            <div class="ratio_name ta3">{{ e.value }}</div>
          </div>
        </div>
        <div class="reset_btn ta3" @click="resetBtn">重置</div>
      </div>

      <div class="generate_btn ta3" @click="aiGenerate">
        <i class="iconfont icon-generate"></i>
        立即生成
      </div>
    </el-aside>
    <el-main>
      <div class="drawing_box">
        <progress-loading :progress="loadingProgress" />
        <div class="drawing_img" v-if="generateImg || requestState">
          <div class="bipartite_box">
            <div class="bipartite_bg">
              <preview-img :previewImg="generateImg" :dName="toolTitle" />
            </div>
          </div>
        </div>
        <div class="drawing_empty dfccc" v-else>
          <img src="@/assets/images/aiAsst/common/empty_img.png" alt="" />
          <p>
            快将你脑海中的画面通过文字描述出来吧 <br />
            实现从想象到视觉呈现的奇妙跨越
          </p>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import aiInput from "@/components/aiInput.vue";
import progressLoading from "@/components/progressLoading.vue";
import previewImg from "@/components/previewImg.vue";
import { useGenerate } from "@/utils/useGenerate";

export default {
  name: "Drawing",
  components: { aiInput, progressLoading, previewImg },
  data() {
    return {
      toolTitle: "AI文生图",
      drawingHint: '请输入提示词 例如："戴墨镜的黑色小猫"',
      ratioList: [
        { ratio: "1:1", value: "头像比例", size: "1024x1024" },
        { ratio: "16:9", value: "电脑壁纸", size: "1024x576" },
        { ratio: "9:16", value: "手机媒体", size: "576x1024" },
        { ratio: "4:3", value: "摄影比例", size: "1024x768" },
      ], // 比例数组
      currentRatio: "1024x1024", // 当前比例
      drawingVal: "", // 生成提示词

      // ai生成
      drawGenerate: "",
      requestState: "",
      loadingProgress: -1,
      generateImg: "",
    };
  },
  mounted() {
    useGenerate(this); // 生成
    this.getRatioList();
  },
  methods: {
    // 获取提示词
    getDrawingVal(val) {
      this.drawingVal = val;
    },

    // 比例数组处理
    getRatioList() {
      let commonLength = 30;
      this.ratioList.forEach((e, i) => {
        let originalArr = e.ratio.split(":").map(Number);
        e.width = `${commonLength}px`;
        e.height = `${commonLength}px`;
        if (originalArr[0] > originalArr[1]) {
          e.height = `${(originalArr[1] / originalArr[0]) * commonLength}px`;
        } else if (originalArr[0] < originalArr[1]) {
          e.width = `${(originalArr[0] / originalArr[1]) * commonLength}px`;
        }
        // 强制视图刷新
        this.$set(this.ratioList, i, this.ratioList[i]);
      });
    },
    // 选择比例
    selectRatio(val) {
      this.currentRatio = val;
    },

    // 重置按钮
    resetBtn() {
      if (this.requestState) {
        this.$message({
          message: "ai正在处理中,请勿操作",
          type: "error",
          duration: 6000,
        });
        return;
      }
      this.drawingVal = "";
      this.generateImg = "";
      this.currentRatio = "1024x1024";
    },

    // ai生成
    aiGenerate() {
      let obj = {
        prompt: this.drawingVal,
        action: "generate",
        scale: this.currentRatio,
        model: "llmf",
      };
      this.drawGenerate(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.ratio_box {
  display: flex;
  gap: 5px;

  .ratio_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    color: #808694;
    font-size: 12px;

    .ratio_top {
      width: 74px;
      height: 74px;
      border: 1px solid #e8eaee;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      .ratio_image_box {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ratio_image {
          border: 1px solid #808694;
          border-radius: 2px;
        }
      }
    }
  }

  .active,
  .ratio_item:hover {
    color: #25c894;

    .ratio_top {
      border-color: #25c894;

      .ratio_image {
        border-color: #25c894 !important;
      }
    }
  }
}

.el-main {
  position: relative;

  .drawing_box {
    position: relative;

    .drawing_img {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .drawing_empty {
      position: relative;
      gap: 20px;
      padding-bottom: 150px;
      min-width: 752px;
      min-height: 752px;

      img {
        width: 234px;
        height: 234px;
      }

      p {
        font-size: 16px;
        color: #777;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
</style>
