<template>
  <el-container>
    <el-aside class="creation_aside">
      <div class="aside_main">
        <h2 class="tool_title">上传底图</h2>
        <aside-upload class="picture_upload" @successUpload="getImg" />
        <h2 class="tool_title">文字描述</h2>
        <ai-input :inputHint="pictureHint" @getVal="getPictureVal" />
      </div>
      <div class="generate_btn ta3" @click="aiGenerate">
        <i class="iconfont icon-generate"></i>
        立即生成
      </div>
    </el-aside>
    <el-main class="picture_main">
      <progress-loading :progress="loadingProgress" />
      <div class="picture_img" v-if="generateImg || requestState">
        <div class="bipartite_box">
          <div class="bipartite_bg">
            <preview-img
              :previewImg="generateImg"
              :dName="toolTitle"
              @againImage="againImage"
              showAgain
            />
          </div>
        </div>
      </div>
      <div class="picture_empty dfccc" v-else>
        <img src="@/assets/images/aiAsst/common/empty_img.png" alt="" />
        <p>
          快将你脑海中的画面通过文字描述出来吧 <br />
          实现从想象到视觉呈现的奇妙跨越
        </p>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import aiInput from "@/components/aiInput.vue";
import asideUpload from "@/components/asideUpload.vue";
import progressLoading from "@/components/progressLoading.vue";
import previewImg from "@/components/previewImg.vue";
import { useGenerate } from "@/utils/useGenerate";

export default {
  name: "Picture",
  components: { aiInput, asideUpload, progressLoading, previewImg },
  data() {
    return {
      toolTitle: "AI图像重绘",
      pictureVal: "", // 生成提示词
      pictureHint: '请输入提示词 例如："戴墨镜的黑色小猫"',
      baseImg: "", // 上传底图

      // ai生成
      drawGenerate: "",
      requestState: "",
      loadingProgress: -1,
      generateImg: "",
    };
  },
  mounted() {
    useGenerate(this); // 生成
  },
  methods: {
    // 获取提示词
    getPictureVal(val) {
      this.pictureVal = val;
    },

    // 重置
    againImage() {
      this.generateImg = "";
    },

    // 获取图片
    getImg(val) {
      this.baseImg = val;
    },

    // ai生成
    aiGenerate() {
      let obj = {
        prompt: this.pictureVal,
        action: "generate",
        image_base: this.baseImg,
        model: "llmf",
      };
      this.drawGenerate(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.picture_main {
  position: relative;

  .picture_empty {
    position: relative;
    gap: 20px;
    padding-bottom: 150px;
    min-width: 752px;
    min-height: 752px;

    img {
      width: 234px;
      height: 234px;
    }

    p {
      font-size: 16px;
      color: #777;
      line-height: 24px;
      text-align: center;
    }
  }
}

.picture_upload {
  margin-bottom: 15px;
}
</style>