<template>
  <div class="aside_upload_box ta3">
    <el-upload
      ref="asideUpload"
      class="aside_upload"
      drag
      multiple
      :action="actionUrl"
      :accept="acceptType"
      :show-file-list="false"
      :limit="uploadLimit"
      :before-upload="beforeUpload"
      :on-exceed="handleExceed"
      :on-success="onSuccess"
      :on-progress="onProgress"
    >
      <i class="el-icon-plus"></i>
      <p class="upload_tips">点击或将照片拖拽到这里上传</p>
    </el-upload>
    <transition name="el-zoom-in-center">
      <div class="loding_box" v-show="uploadLoading">
        <div class="loding_icon">
          <i class="el-icon-top"></i>
        </div>
        <div class="progress_text">上传进度: {{ uploadPercent }} %</div>
      </div>
    </transition>
    <div class="img_box" v-show="uploadImg">
      <el-image class="upload_img" :src="uploadImg" fit="contain">
        <template #placeholder>
          <div class="img_loading">
            <i class="iconfont icon-loading"></i>
            加载图片中...
          </div>
        </template>
      </el-image>
      <div class="re_upload_box ta3">
        <div class="re_btn ta3" @click="reUpload">重新上传</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsideUpload",
  props: {
    // 上传api
    actionUrl: {
      default: `${process.env.VUE_APP_UPLOAD_IMG_URL}/api/upload`,
      type: String,
    },
    // 允许上传文件
    acceptType: {
      default: ".jpeg,.jpg,.png",
      type: String,
    },
    // 上传文字提示
    uploadtext: {
      default: "每张图片大小限制5M以内，尺寸最长边小于2000*2000px",
      type: String,
    },
    // 最大上传大小(单位M)
    maxFileSize: {
      default: 5,
      type: Number,
    },
    // 上传数量
    uploadLimit: {
      default: 1,
      type: Number,
    },
  },
  components: {},
  data() {
    return {
      uploadLoading: false,
      uploadPercent: 0,
      uploadImg: "",
    };
  },
  mounted() {},
  methods: {
    // 上传前验证
    beforeUpload(rawFile) {
      let allowArr = this.acceptType.replaceAll(".", "image/").split(",");

      if (allowArr.indexOf(rawFile.type) == -1) {
        this.$message.error(`暂只支持上传${this.acceptType}文件`);
        return false;
      } else if (rawFile.size / 1024 / 1024 > this.maxFileSize) {
        this.$message.error(`图片大小需不超过${this.maxFileSize}M`);
        return false;
      }
      return true;
    },

    // 上传超过限制
    handleExceed(files) {
      if (this.uploadLimit == 1) {
        this.$refs.asideUpload.clearFiles();
        // this.$refs.asideUpload.submit();
      } else {
        this.$message.error(`只能上传${this.uploadLimit}个文件`);
      }
    },

    // 上传中
    onProgress(progressEvent) {
      this.uploadLoading = true;
      this.uploadPercent = progressEvent.percent.toFixed(0);
    },

    // 上传成功
    onSuccess(res, file) {
      this.uploadLoading = false;
      this.uploadPercent = 0;
      this.uploadImg = this.$prefixSrc(res.data.path);
      this.$emit("successUpload", this.$prefixSrc(res.data.path));
    },

    // 重新上传
    reUpload() {
      this.uploadImg = "";
      this.$emit("successUpload", "");
      this.$refs.asideUpload.clearFiles();
    },
  },
};
</script>

<style lang="scss" scoped>
.aside_upload_box {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border: 1px dashed #d5d5d5;

  &:hover {
    border-color: #434343;
  }

  .aside_upload {
    background: #fff;

    ::v-deep .el-upload {
      display: block;
      .el-upload-dragger {
        width: 100%;
        height: 140px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        color: #777;
        border: none;
        transition: all 0.3s;
        background: #f5f5f5;

        i {
          font-size: 25px;
        }

        .upload_tips {
          font-size: 14px;
        }

        .extension_box {
          line-height: 18px;
          margin-bottom: 4px;
        }
      }

      .el-upload-dragger.is-dragover {
        background: rgba(34, 90, 247, 0.06);
      }
    }
  }

  .loding_box {
    width: 100%;
    height: 368px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .loding_icon {
      height: 80px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-icon {
        font-size: 60px;
        font-weight: bold;
        animation: topTranslate 2s infinite;
      }
    }
  }

  .img_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f5f5;

    .upload_img {
      width: 100%;
      height: 100%;
    }

    .re_upload_box {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .re_btn {
        width: 100px;
        height: 34px;
        background: #fff;
        border-radius: 4px;
        color: #333;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        line-height: 34px;

        &:hover {
          background: #25c894;
          color: #fff;
        }
      }
    }

    &:hover {
      .re_upload_box {
        opacity: 1;
      }
    }
  }
}


</style>
