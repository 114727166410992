<template>
  <div class="return-box">
    <div
      class="download-box"
      :class="isDownload ? 'show_d' : ''"
      @mouseover="isDownload = true"
      @mouseleave="isDownload = false"
    >
      <i class="arrow1"></i>
      <div class="tipwarp_1">
        <img
          class="download-i"
          src="@/assets/images/charts/download-ewm.png"
          alt=""
        />
        <p class="download-t">
          <img class="ios-t" src="@/assets/images/charts/ios.png" alt="" />
          <img
            class="android-t"
            src="@/assets/images/charts/Android.png"
            alt=""
          />下载APP
        </p>
      </div>
      <img
        class="download"
        v-if="isDownload"
        src="@/assets/images/charts/icon3.png"
        alt=""
      />
      <img
        class="download"
        v-else
        src="@/assets/images/charts/icon4.png"
        alt=""
      />
      <p class="download-text">下载APP</p>
    </div>
    <div
      class="weixin-box"
      :class="isWeixin ? 'show_d' : ''"
      @mouseover="isWeixin = true"
      @mouseleave="isWeixin = false"
    >
      <i class="arrow2"></i>
      <div class="tipwarp_2">
        <img
          class="weixin-i"
          src="@/assets/images/charts/weixin-ewm.png"
          alt=""
        />
        <p class="weixin-t">扫描二维码<br />关注微信公众号</p>
      </div>
      <img
        class="weixin"
        v-if="isWeixin"
        src="@/assets/images/charts/icon1.png"
        alt=""
      />
      <img
        class="weixin"
        v-else
        src="@/assets/images/charts/icon2.png"
        alt=""
      />
      <p class="weixin-text">公众号</p>
    </div>
    <div
      class="qq-box"
      @click="consultqq()"
      :class="isQQ ? 'show_d' : ''"
      @mouseover="isQQ = true"
      @mouseleave="isQQ = false"
    >
      <img
        class="qq"
        v-if="isQQ"
        src="@/assets/images/charts/icon7.png"
        alt=""
      />
      <img class="qq" v-else src="@/assets/images/charts/icon6.png" alt="" />
      <p class="qq-text">在线客服</p>
    </div>
    <div
      class="return-top"
      @click="gotoTop()"
      v-if="isReturn"
      :class="isReturnHover ? 'rt-bgc' : ''"
      @mouseover="isReturnHover = true"
      @mouseleave="isReturnHover = false"
    >
      <p class="rt-text" v-show="isReturnHover">返回<br />顶部</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDownload: false,
      isWeixin: false,
      isQQ: false,
      isReturn: false,
      scrollTop: 0,
      isReturnHover: false,
    };
  },
  methods: {
    gotoTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // 保存滚动值，这是兼容的写法
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop > 200) {
        this.isReturn = true;
      } else {
        this.isReturn = false;
      }
    },
    consultqq() {
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=1983151284&site=qq&menu=yes",
        "newwindow",
        "height=600, width=750, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no"
      );
    },
  },
  mounted() {
    window.onscroll = function () {
      //为了保证兼容性，这里取两个值，哪个有值取哪一个
      //scrollTop就是触发滚轮事件时滚轮的高度
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log("滚动距离" + scrollTop);
    };
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.return-box {
  width: 64px;
  height: auto;
  background-color: #fff;
  text-align: center;
  font-size: 12px;
  color: #13a48e;
  border-radius: 5px;
  position: fixed;
  top: 60%;
  right: 22px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  box-sizing: content-box;
}
.download-box {
  position: relative;
  height: 60px;
  padding: 8px 6px 0;
  border-radius: 5px 5px 0 0;
  box-sizing: content-box;
}
.weixin-box {
  border-top: 1px solid #eee;
  height: 58px;
  position: relative;
  padding: 10px 6px 0;
  box-sizing: content-box;
}
.qq-box {
  border-top: 1px solid #eee;
  height: 58px;
  position: relative;
  padding: 10px 6px 0;
  border-radius: 0 0 5px 5px;
  box-sizing: content-box;
  cursor: pointer;
}
.return-top {
  border-top: 1px solid #eee;
  padding: 10px 0;
  color: #fff;
  height: 24px;
  border-radius: 0 0 5px 5px;
  box-sizing: content-box;
  cursor: pointer;
  background: url("@/assets/images/charts/icon5.png") center center no-repeat;
  background-size: 40%;
}
.download {
  width: 20px;
  margin: 0;
  height: auto;
}
.download-text,
.weixin-text,
.qq-text {
  margin-top: 7px;
}
.weixin-box img {
  width: 33px;
}
.qq-box img {
  width: 28px;
}
.return-top img {
  width: 24px;
}
.tipwarp_1,
.tipwarp_2 {
  display: none;
  background-color: #fff;
  padding: 14px 0 10px;
  width: 134px;
  position: absolute;
  right: 71px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 0px #d9d9d9;
  top: 4px;
}
.tipwarp_1 .download-i,
.tipwarp_2 .weixin-i {
  width: 110px;
}
.arrow1,
.arrow2 {
  display: none;
}
.arrow1 {
  position: absolute;
  border: 7px solid transparent;
  border-left-color: #fff;
  width: 0;
  height: 0;
  top: 27px;
  right: 57px;
  z-index: 10;
}
img {
  vertical-align: middle;
}
.download-t {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #999;
  line-height: 20px;
  margin: 8px 0px 0px 4px;
}
.ios-t {
  width: 18px;
  height: 22px;
  margin: -2px 10px 0px -8px;
}
.android-t {
  width: 19px;
  height: 22px;
  margin: 0px 5px 0px -2px;
}
.arrow2 {
  position: absolute;
  border: 7px solid transparent;
  border-left-color: #fff;
  width: 0;
  height: 0;
  top: 27px;
  right: 57px;
  z-index: 10;
}
.weixin-t {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #999;
}
.show_d .arrow1,
.show_d .arrow2 {
  display: block;
}
.show_d .tipwarp_1,
.show_d .tipwarp_2 {
  display: block;
}
.show_d {
  background-color: #13a48e;
}
.show_d .download-text,
.show_d .weixin-text,
.show_d .qq-text {
  color: #fff;
}
.rt-text {
  padding-top: 4px;
}
.rt-bgc {
  background-color: #13a48e;
  height: 36px;
  padding: 4px 0;
}
</style>