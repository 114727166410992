import axios from 'axios'
import { Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
import QS from 'qs';
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: '/myApi',
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['X-ISAPI'] = 1
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    if (config.method == 'post') {
      config.data = {
        ...config.data,
      }
      config.data = QS.stringify(config.data)
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    const headers2 = response.headers
    if (headers2['content-type'] === 'application/octet-stream;charset=utf-8') {
      return res;
    }
    if (res.status == undefined || res.status === true || res.code === 20000) {
      return res
    } else {
      Message({
        message: res.error_msg || 'Error',
        type: 'error',
        duration: 5 * 1000,
        customClass: 'ErrorZIndex'
      })
    }
    return res;
  },
  error => {
    // console.log('err:' + JSON.stringify(error)) // for debug
    const http_code = error.response && error.response.status || ''
    let error_msg = error.response && error.response.data.error_msg || ''
    let isDataCode = error.response && error.response.data && error.response.data.error_code
    if (!error_msg) {
      error_msg = error.response.data.errors
    }
    if (http_code === 400 && isDataCode != '10001' || http_code === 422 && isDataCode != '10001') {
      Message({
        message: error_msg,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error_msg)
    } else if (isDataCode == 10001) {
      Message({
        message: error_msg,
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(isDataCode)
    } else if (http_code === 401) {
      removeToken();
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
  }
)

export default service
