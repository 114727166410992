
const topicRouter = [
    {
        path: '/kaixue',
        component: () => import('@/views/topic/kaixue'),
        meta: { title: '开学了，学点啥？从新生到准毕业生，这些设计必备技能你都会了吗？' }
    },
    {
        path: '/ss',
        component: () => import('@/views/topic/hd001'),
        meta: { title: '设计入门必学软件宝典' }
    },
    {
        path: '/color',
        component: () => import('@/views/topic/hd002'),
        meta: { title: '专题路径' }
    },
    {
        path: '/vlog',
        component: () => import('@/views/topic/hd003'),
        meta: { title: '专题路径' }
    },
    {
        path: '/retrospect2022',
        component: () => import('@/views/topic/hd004'),
        meta: { title: '专题路径' }
    },
    {
        path: '/aidraw',
        component: () => import('@/views/topic/hd005'),
        meta: { title: '专题路径' }
    },
    {
        path: '/ppt',
        component: () => import('@/views/topic/hd006'),
        meta: { title: '专题路径' }
    },
    {
        path: '/ae',
        component: () => import('@/views/topic/hd007'),
        meta: { title: '专题路径' }
    },
    {
        path: '/ps',
        component: () => import('@/views/topic/hd008'),
        meta: { title: '专题路径' }
    },
    {
        path: '/dssj',
        component: () => import('@/views/topic/hd009'),
        meta: { title: '专题路径' }
    },
    {
        path: '/gameDesign',
        component: () => import('@/views/topic/hd010'),
        meta: { title: '专题路径' }
    },
    {
        path: '/festivalDesign',
        component: () => import('@/views/topic/hd011'),
        meta: { title: '专题路径' }
    },
    {
        path: '/career',
        component: () => import('@/views/topic/hd012'),
        meta: { title: '专题路径' }
    },
]

export default topicRouter