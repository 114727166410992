<template>
  <div>
    <div class="ai_input">
      <el-input
        v-model="aiTextarea"
        :rows="5"
        type="textarea"
        :maxlength="inputMaxLength"
        resize="none"
        class="ta3"
        :placeholder="inputHint"
        @input="getInputVal"
      />
      <div class="max_del">
        <div class="max_box">
          <span>{{ aiTextarea.length }}</span
          >/{{ inputMaxLength }}
        </div>
        <el-tooltip effect="dark" content="清空" placement="right">
          <div class="del_box ta3" @click="emptyTextarea">
            <i class="iconfont icon-delete2 ta3"></i>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="example_box" v-if="showExample">
      <div class="example_left">
        示例：
        <div
          class="example_item"
          v-for="(e, i) in randomList"
          :key="i"
          @click="prefabricateVal(e.value)"
        >
          {{ e.label }}
        </div>
      </div>
      <div
        class="example_right ta3"
        @click="randomList = randomCallWordList(callWordList)"
      >
        <i class="iconfont icon-refresh"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AiInput",
  props: {
    inputVal: {
      default: "",
      type: String,
    },
    inputHint: {
      default: "",
      type: String,
    },
    inputMaxLength: {
      default: 800,
      type: Number,
    },
    // 例子显隐
    showExample: {
      default: false,
      type: Boolean,
    },
    // 例子类型
    exampleType: {
      default: "",
      type: String,
    },
  },
  components: {},
  data() {
    return {
      aiTextarea: "",
      callWordList: [],
      randomList: [],
    };
  },
  mounted() {
    if (this.showExample) this.getCallWord();
  },
  methods: {
    getInputVal() {
      this.$emit("getVal", this.aiTextarea);
    },

    emptyTextarea() {
      this.aiTextarea = "";
      this.$emit("getVal", "");
    },

    getCallWord() {
      fetch(`https://yihui.art/callWord.json`)
        .then((response) => response.json())
        .then((res) => {
          this.callWordList = res[this.exampleType];
          this.randomList = this.randomCallWordList(res[this.exampleType]);
        })
        .catch((error) => console.error("Error loading the JSON file:", error));
    },

    randomCallWordList(arr) {
      let randomIndex1 = Math.floor(Math.random() * arr.length);
      let randomIndex2 = randomIndex1; // 初始化为相同的索引，以便替换

      do {
        randomIndex2 = Math.floor(Math.random() * arr.length);
      } while (randomIndex1 === randomIndex2); // 确保两个索引不同

      let myArr = [arr[randomIndex1], arr[randomIndex2]];
      return myArr;
    },

    prefabricateVal(val) {
      this.aiTextarea = val;
      this.$emit("getVal", this.aiTextarea);
    },
  },
};
</script>

<style lang="scss" scoped>
.ai_input {
  position: relative;

  ::v-deep .el-textarea {
    padding: 10px 4px 40px 10px;
    border: 1px solid #e7e9ee;
    border-radius: 10px;
    textarea::placeholder {
      color: #a8abb2;
    }

    &:focus-within {
      border-color: #25c894;

      .iconfont {
        color: #6b92ff;
      }
    }

    .el-textarea__inner {
      padding: 0;
      padding-bottom: 25px;
      border: none;

      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        /*高宽分别对应横竖滚动条的尺寸*/
        width: 6px;
        height: 1px;
      }

      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        background: #c7d6ff;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        background: transparent;
      }
    }

    .el-input__count {
      color: #cccdd1;
      right: 53px;
      bottom: 15px;
    }
  }

  .max_del {
    position: absolute;
    right: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    gap: 15px;

    .max_box {
      font-size: 12px;
      color: #cccdd1;

      span {
        color: #333;
      }
    }

    .del_box {
      width: 26px;
      height: 26px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      i {
        font-size: 16px;
        color: #333;
      }

      &:hover {
        background: #e7e9ee;

        i {
          color: #25c894;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      right: 33px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 14px;
      background: #cbcdd1;
    }
  }
}

.example_box {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .example_left {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: #333;

    .example_item {
      cursor: pointer;
    }
  }

  .example_right {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 2px;

    i {
      font-size: 12px;
      color: #979797;
    }

    &:hover {
      background: #e7e9ee;

      i {
        animation: rotate_animation 0.5s ease-in-out;
      }
    }
  }
}
</style>
