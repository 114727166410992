<template>
  <transition name="el-fade-in-linear">
    <div class="preview_img" v-show="previewImg">
      <el-tooltip effect="dark" content="下载图片" placement="top" :offset="8">
        <div
          class="preview_btn ta3"
          style="left: 10px"
          @click="$downloadImage(previewImg, dName)"
        >
          <i class="iconfont icon-download"></i>
        </div>
      </el-tooltip>
      <div
        class="preview_btn ta3"
        style="right: 10px"
        @click="againBtn"
        v-if="showAgain"
      >
        <i class="iconfont icon-close"></i>
      </div>

      <el-image
        class="generate_img"
        :src="previewImg"
        :preview-src-list="[previewImg]"
        fit="contain"
      >
        <template #placeholder>
          <div class="img_loading">
            <i class="iconfont icon-loading"></i>
            加载图片中...
          </div>
        </template>
        <template #error>
          <span></span>
        </template>
      </el-image>
    </div>
  </transition>
</template>

<script>
export default {
  name: "previewImg",
  props: {
    // 预览图
    previewImg: {
      default: "",
      type: String,
    },
    // 下载名称
    dName: {
      default: "",
      type: String,
    },
    // 重置显隐
    showAgain: {
      default: false,
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    againBtn() {
      this.$emit("againImage", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.preview_img {
  width: 100%;
  height: 100%;
  position: relative;

  .preview_btn {
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ddd;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    z-index: 1;

    &:hover {
      color: #fff;
    }
  }

  .generate_img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
</style>
