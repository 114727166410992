<template>
  <el-container class="toolkit">
    <el-aside width="100px" class="toolkit_aside">
      <toolkit-list></toolkit-list>
    </el-aside>
    <el-main class="toolkit_main">
      <component :is="currentTool"></component>
      <policy-wrap
        :placeLeft="isAside ? 'calc(50% + 173px)' : '50%'"
      ></policy-wrap>
    </el-main>
  </el-container>
</template>

<script>
import policyWrap from "@/components/policyWrap.vue";
import toolkitList from "./components/toolkitList.vue";
import drawing from "./components/drawing.vue";
import picture from "./components/picture.vue";
import magnify from "./components/magnify.vue";
import colour from "./components/colour.vue";
import removeBackground from "./components/removeBackground.vue";
import repair from "./components/repair.vue";
import clayStylize from "./components/clayStylize.vue";
import stylize3D from "./components/3DStylize.vue";
import paster from "./components/paster.vue";

export default {
  name: "Toolkit",
  components: { policyWrap, toolkitList, drawing },
  data() {
    return {
      comTool: [
        {
          type: "drawing",
          com: drawing,
        },
        {
          type: "picture",
          com: picture,
        },
        {
          type: "magnify",
          com: magnify,
        },
        {
          type: "colour",
          com: colour,
        },
        {
          type: "removeBackground",
          com: removeBackground,
        },
        {
          type: "repair",
          com: repair,
        },
        {
          type: "clayStylize",
          com: clayStylize,
        },
        {
          type: "3DStylize",
          com: stylize3D,
        },
        {
          type: "paster",
          com: paster,
        },
      ],
      currentTool: undefined,
      asideArr: ["drawing", "picture", "paster"],
      isAside: true,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isAside = this.asideArr.includes(this.$route.params.type);
      this.currentTool = this.comTool.find((e) => {
        return e.type == this.$route.params.type;
      }).com;
    },
  },
  watch: {
    "$route.params.type": function (newVal, oldVal) {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.toolkit {
  width: 100%;
  height: 100%;
  .toolkit_aside {
    background: #fff;
  }

  .toolkit_main {
    background: #f4f6f9;
    position: relative;
    min-height: 630px;
    overflow: hidden;
  }
}
</style>
