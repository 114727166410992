<template>
  <div class="draw_box">
    <div class="draw_main">
      <div class="draw_item">
        <h3>图片上传</h3>
        <ai-upload @successUpload="getUploadImg" />
      </div>
      <div class="draw_item">
        <h3>放大效果</h3>
        <div class="draw_result">
          <progress-loading :progress="loadingProgress" />
          <div class="bipartite_bg">
            <preview-img :previewImg="generateImg" :dName="toolTitle" />
          </div>
        </div>
      </div>
    </div>
    <div class="draw_btn">
      <div
        class="magnify_btn ta3"
        :class="{ active: e.value == multiple }"
        v-for="(e, i) in magnifyList"
        :key="i"
        @click="multiple = e.value"
      >
        {{ e.label }}
      </div>
      <div class="generate_btn ta3" @click="aiGenerate">
        <i class="iconfont icon-generate"></i>
        立即放大
      </div>
    </div>
  </div>
</template>

<script>
import aiUpload from "@/components/aiUpload.vue";
import previewImg from "@/components/previewImg.vue";
import progressLoading from "@/components/progressLoading.vue";
import { useGenerate } from "@/utils/useGenerate";
export default {
  name: "Magnify",
  components: { aiUpload, progressLoading, previewImg },
  data() {
    return {
      toolTitle: "AI放大",
      baseImg: "", // 上传底图
      multiple: 2, // 放大倍数
      magnifyList: [
        { value: 2, label: "2倍" },
        { value: 4, label: "4倍" },
      ], //倍数数组

      // ai生成
      drawGenerate: "",
      requestState: "",
      loadingProgress: -1,
      generateImg: "",
    };
  },
  mounted() {
    useGenerate(this);
  },
  methods: {
    // 获取上传图片
    getUploadImg(val) {
      this.baseImg = val;
    },

    // ai生成
    aiGenerate() {
      let obj = {
        style: "enlarge", // 风格 3d clay黏土  paster贴纸  repair修复 rembg 去背景 color上色  enlarge放大
        multiple: this.multiple,
        action: "generate",
        image_base: this.baseImg,
        model: "llmf",
      };
      this.drawGenerate(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.magnify_btn {
  font-size: 14px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #333333;
  cursor: pointer;

  &:hover {
    border-color: #25c894;
    color: #25c894;
  }

  &.active {
    border-color: #25c894;
    background: #25c894;
    color: #fff;
  }
}
</style>
