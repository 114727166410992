const getters = {
  token: state => state.user.token,
  avater: state => state.user.avater,
  name: state => state.user.name,
  toolkitList: state => state.user.toolkitList, // ai创作工具列表
  org_name: state => state.user.org_name || "", // 班级名称
  modulePer: state => state.org.modulePer, // 机构模块显示
  showPer: state => state.org.showPer, // 是否显示限制弹框
}
export default getters
