<template>
  <div class="dialog">
    <el-dialog
      title="温馨提示"
      append-to-body
      modal-append-to-body
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="showPermission"
      width="550px"
      :before-close="handleClose"
    >
      <div class="permission_info">
        您所在的图书馆/学校机构已过期或暂未开通本功能版块，暂时无法使用【{{
          moduleName
        }}】功能内容。如需反馈使用需求，请联系客服。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="consultqq">去反馈</el-button>
        <el-button class="know_btn" type="primary" @click="handleClose"
          >我知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "permissionDialog",
  components: {},
  props: {
    myModule: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      showPermission: false,
    };
  },
  methods: {
    handleClose() {
      this.clearType();
      this.showPermission = false;
    },
    showPermissionDialog() {
      this.showPermission = true;
    },
    consultqq() {
      this.clearType();
      this.showPermission = false;
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=1983151284&site=qq&menu=yes",
        "newwindow",
        "height=600, width=750, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no"
      );
    },
    clearType() {
      if (this.$route.query.perType) {
        this.$router.push("/");
      }
    },
  },
  computed: {
    moduleName(e) {
      switch (e.myModule) {
        case 1:
          return "设计中心";
        case 2:
          return "学习路径";
        default:
          return "设计中心";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 15px;
  overflow: hidden;
}
::v-deep .el-dialog__header {
  padding: 30px 0 20px;
  text-align: center;
  font-weight: bold;
  &::after {
    height: 0;
  }
  .el-dialog__title {
    font-size: 20px;
  }
}
::v-deep .el-dialog__body {
  padding: 0 48px;
  border-radius: 10px;
  .permission_info {
    font-size: 16px;
    letter-spacing: 3px;
    margin-bottom: 50px;
  }
}
::v-deep .el-dialog__footer {
  text-align: center;
  padding-bottom: 50px;
  .el-button {
    min-width: 120px;
    border-radius: 20px;
    transition: all 0.3s;
  }
  .know_btn {
    color: #fff !important;
    cursor: pointer;
    margin-left: 30px;
    background: linear-gradient(
      90deg,
      #1adc8a 0%,
      #23b4a0 50%,
      #26f49e 100%
    ) !important;
    background-size: 200% 100% !important;
    &:hover {
      background-position: -100% 0 !important;
      border: none !important;
    }
  }
}
</style>
