
<template>
  <div class="toolkit_list">
    <div
      class="toolkit_item"
      v-for="(e, i) in toolkitList"
      :key="i"
      :class="{ active: $route.params.type == e.type }"
      @click="goTool(e.type)"
    >
      <img
        :src="require(`@/assets/images/aiAsst/creation/thumbnail${i + 1}.png`)"
        alt=""
      />
      <p>{{ e.name }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Toolkit",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    goTool(type) {
      this.$router.push(`/ai/toolkit/${type}`);
    },
  },
  computed: {
    ...mapGetters(["toolkitList"]),
  },
};
</script>
<style lang="scss" scoped>
.toolkit_list {
  width: 100%;

  .toolkit_item {
    width: 100%;
    height: 77px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 14px;
    color: #777;
    cursor: pointer;
    position: relative;

    img {
      width: 40px;
      height: 40px;
    }

    &::before {
      content: "";
      width: 10px;
      background: #25c894;
      border-radius: 10px;
      position: absolute;
      left: -10px;
      transition: all 0.3s;
    }

    &:hover {
      &::before {
        height: 20px;
        top: 18px;
        left: -5px;
      }
    }
  }

  .active {
    &::before {
      height: 40px !important;
      top: 8px !important;
      left: -5px;
    }
  }
}
</style>