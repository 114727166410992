import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.use(element)

Vue.config.productionTip = false

// vue-video-player
import VueVideoPlayer from 'vue-video-player/src'
window.videojs = VueVideoPlayer.videojs
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
require('video.js/dist/lang/zh-CN.js')
Vue.use(VueVideoPlayer)


// 基础样式
import '@/assets/style/reset.css'
// 动画样式
import '@/assets/style/animation.css'
// emoji样式
import '@/assets/style/emoji.css'
// 公共样式
import '@/assets/style/common.css'
import '@/assets/scss/common.scss'
// 重写element样式
import '@/assets/style/element.css'
// ai助手公共样式
import '@/assets/scss/aiAsstCommon.scss'
// animate.css
import 'animate.css/animate.min.css';

// 全局eventBus
window.eventBus = new Vue();

// 注册自定义指令
import '@/utils/directive.js';

// 全局公共方法
import { prefixSrc, cutStr, getNumbers, avatarSrc, homeSrc, leftDay, downloadImage } from '@/utils/common'
import { emoji } from '@/utils/emoji.js'

Vue.prototype.$prefixSrc = prefixSrc
Vue.prototype.$emoji = emoji
Vue.prototype.$homeSrc = homeSrc
Vue.prototype.$avatarSrc = avatarSrc
Vue.prototype.$leftDay = leftDay
Vue.prototype.$downloadImage = downloadImage

// 全局过滤器
import filters from "./filters/index"

Object.keys(filters).map(v => {
  Vue.filter(v, filters[v])
})

// vue-awesome-swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// 注册全局组件
import CommonHeader from '@/components/header'
import CommonFooter from '@/components/footer'
import Pagination from "@/components/Pagination";
import LoginDialog from "@/components/Dialog/login.vue";
import RegisterDialog from "@/components/Dialog/register.vue";
import PermissionDialog from "@/components/Dialog/permission.vue";

import Rtbox from '@/components/rtbox'

Vue.component("common-header", CommonHeader)
Vue.component("common-footer", CommonFooter)
Vue.component("pagination", Pagination)
Vue.component("loginDialog", LoginDialog)
Vue.component("registerDialog", RegisterDialog)
Vue.component("permissionDialog", PermissionDialog)

Vue.component("rtbox", Rtbox)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
