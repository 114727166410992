import data from '../data/emoji-data.js'
let emojiData = {}
let emojiVal = []
Object.values(data).forEach(item => {
  emojiData = { ...emojiData, ...item }
})
/**
 * @export
 * @param {string} value
 * @returns {string}
 */
export function emoji(value) {
  emojiVal = value.split(':')
  if (!value) return
  Object.keys(emojiData).some(item => {
    emojiVal.some(e => {
      if (`:${e}:` == item) {
        value = String(value)
        value = value.replace(new RegExp(item, 'g'), createIcon(item))
      }
    })
  })
  return value
}

function createIcon(item) {
  return `<img src="${require('/static/emoji/' + emojiData[item])}" width="16px" height="16px">`
}
