import { getPersonalInfo } from '@/api/home'
import { getToken, setToken, removeToken } from '@/utils/auth'
const state = {
  token: getToken(),
  name: '',
  avater: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
  org_name: '',
  toolkitList: [
    {
      name: 'AI绘画',
      introduce: '文字描述即可生成图片',
      type: 'drawing',
    },
    {
      name: 'AI图像重绘',
      introduce: '导入图片AI为您更换风格',
      type: 'picture',
    },
    {
      name: 'AI放大',
      introduce: '无限放大图片，使图片更清晰',
      type: 'magnify',
    },
    {
      name: 'AI照片上色',
      introduce: '老旧黑白照片上色AI助手',
      type: 'colour',
    },
    {
      name: 'AI去除背景',
      introduce: 'AI去除图片背景',
      type: 'removeBackground',
    },
    {
      name: 'AI照片修复',
      introduce: '老旧模糊照片修复AI助手',
      type: 'repair',
    },
    {
      name: 'AI黏土风格化',
      introduce: 'AI为你把人像照片转换成黏土风格',
      type: 'clayStylize',
    },
    {
      name: 'AI 3D风格化',
      introduce: 'AI为你把人像照片转换成3D风格',
      type: '3DStylize',
    },
    {
      name: 'AI贴纸',
      introduce: '工作流：AI贴纸',
      type: 'paster',
    },
  ]
}
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avater) => {
    if (avater == '') {
      state.avater = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
    } else {
      state.avater = avater
    }
  },
  SET_ORG_NAME: (state, org_name) => {
    state.org_name = org_name
  },
  SET_TOOLKITLIST: (state, toolkitList) => {
    console.log(state, 55699)
    state.toolkitList = toolkitList
  },
}

const actions = {
  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getPersonalInfo().then(response => {
        const { status, error_msg, data } = response
        if (status === false) {
          reject(error_msg)
        }
        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const { member_name, head_url, member_id } = data.info;
        const { student_org } = data
        commit('SET_NAME', member_name);
        // commit('SET_AVATAR', head_url);
        commit('SET_ORG_NAME', student_org);
        // commit('SET_IDENTYPE', identity_type);
        // commit('SET_LOGINID', id);
        // commit('SET_OID', oid);
        // commit('SET_GRADE', grade);
        // commit('SET_CARDNO', card_no);
        // commit('SET_CARDINFO', org);
        // commit('SET_VERIFIED', is_verified)

        // commit('INIT')
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
