import { Message } from 'element-ui'
import { drawImg, drawDetail } from '@/api/creation';
import { getToken } from '@/utils/auth';
import usePolling from './usePolling';
// ai生成
export const useGenerate = (vm) => {
    vm.requestState = false // ai接口请求状态
    vm.loadingProgress = -1 // 加载进度 -1:不显示加载组件
    vm.generateImg = '' // 生成图片



    let timerId = undefined // 加载进度定时器
    let recordsId = undefined // 作品id

    // 作品详情
    const useDetail = () => {

        const check = async () => {
            try {
                if (!recordsId) return end()
                const res = await drawDetail({ records_id: recordsId })
                if (res.code) {
                    const data = res.data[0]
                    switch (data.status) {
                        case 3:
                            end()
                            clearInterval(timerId);
                            vm.loadingProgress = 100
                            setTimeout(() => {
                                vm.loadingProgress = -1
                                vm.generateImg = data.image_url
                                vm.requestState = false
                            }, 500)
                            break;
                        case 2:
                            fail()
                            vm.generateImg = data.image_url
                            break;
                    }
                } else {
                    fail(res.msg)
                }

            } catch (error) {
                fail()
                console.log('获取详情失败', error)
            }
        }
        const endCallback = () => {
            end()
        }

        // 作品详情轮询
        const { start, end, result } = usePolling(check, {
            key: 'app/drawing',
            totalTime: 10 * 60 * 1000,
            time: 2000,
            callback: endCallback
        })

        return { start, end, result }
    }
    const { start, end } = useDetail()
    // 获取失败
    const fail = (msg = '绘画失败') => {
        end()
        clearInterval(timerId);
        vm.loadingProgress = -1
        vm.requestState = false
        Message({
            message: msg,
            type: 'error',
            duration: 6000
        })
    }
    function drawGenerate(options = {}) {
        if (!getToken()) return eventBus.$emit("openLoginDialog");
        // 虚假进度
        const shamProgress = () => {
            vm.loadingProgress = 0
            timerId = setInterval(() => {
                vm.loadingProgress = vm.loadingProgress + Math.floor(Math.random() * 4) + 1;
                if (vm.loadingProgress >= 95) {
                    vm.loadingProgress = 95;
                    clearInterval(timerId);
                }
            }, 500);
        }
        if (vm.requestState) {
            Message({
                message: 'ai正在处理中,请勿操作',
                type: 'error',
                duration: 6000
            })
            return
        }
        if (Object.keys(options).includes('image_base') && !options.image_base) {
            Message({
                message: '请上传参考图',
                type: 'error',
                duration: 6000
            })
            return
        }
        if (Object.keys(options).includes('prompt') && !options.prompt) {
            Message({
                message: '请输入提示词',
                type: 'error',
                duration: 6000
            })
            return
        }
        vm.requestState = true;
        shamProgress()
        drawImg(options).then(res => {
            if (res.code == 1) {
                recordsId = res.data.records_id
                start()
            } else {
                fail(res.msg == "余额不足" ? '已达今日AI创作体验点数上限，请明日再进行体验' : res.msg)
            }

        }).catch(err => {
            end()
            clearInterval(timerId);
            vm.loadingProgress = -1
            vm.requestState = false
        })
    }
    vm.drawGenerate = drawGenerate
}
