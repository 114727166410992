import request from '@/utils/yihuiRequest'


// 生成图片 
export const drawImg = (data) => request.post(`/api/draw/imagine`, data)

// 绘图记录 
export const getRecords = (params) => request.get(`/api/draw_records/records`, { params })

// 绘图详情
export const drawDetail = (data) => request.post(`/api/draw_records/detail`, data)

// 删除绘图记录
export const delImgs = (data) => request.post(`/api/draw_records/delete`, data)
