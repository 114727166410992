<template>
  <el-container>
    <el-aside class="creation_aside">
      <div class="aside_main">
        <h2 class="tool_title">{{ toolTitle }}</h2>
        <ai-input
          :inputVal="pasterVal"
          :inputHint="pasterHint"
          @getVal="getPasterVal"
          showExample
          exampleType="paster"
        />
        <div class="reset_btn ta3" @click="resetBtn">重置</div>
      </div>

      <div class="generate_btn ta3" @click="aiGenerate">
        <i class="iconfont icon-generate"></i>
        立即生成
      </div>
    </el-aside>
    <el-main>
      <div class="paster_box">
        <progress-loading :progress="loadingProgress" />
        <div class="paster_img" v-if="generateImg || requestState">
          <div class="bipartite_box">
            <div class="bipartite_bg">
              <preview-img :previewImg="generateImg" :dName="toolTitle" />
            </div>
          </div>
        </div>
        <div class="paster_empty" v-else>
          <img src="@/assets/images/aiAsst/common/empty_img.png" alt="" />
          <p>
            快将你脑海中的画面通过文字描述出来吧 <br />
            实现从想象到视觉呈现的奇妙跨越
          </p>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import aiInput from "@/components/aiInput.vue";
import previewImg from "@/components/previewImg.vue";
import progressLoading from "@/components/progressLoading.vue";
import { useGenerate } from "@/utils/useGenerate";
export default {
  name: "Paster",
  components: { aiInput, previewImg, progressLoading },
  data() {
    return {
      toolTitle: "AI贴纸",
      pasterVal: "", // 生成提示词
      pasterHint: '请输入提示词 例如："戴墨镜的黑色小猫"',

      // ai生成
      drawGenerate: "",
      requestState: "",
      loadingProgress: -1,
      generateImg: "",
    };
  },
  mounted() {
    useGenerate(this);
  },
  methods: {
    // 获取生成提示词
    getPasterVal(val) {
      this.pasterVal = val;
    },

    // 重置按钮
    resetBtn() {
      if (this.requestState) {
        this.$message({
          message: "ai正在处理中,请勿操作",
          type: "error",
          duration: 6000,
        });
        return;
      }
      this.pasterVal = "";
      this.generateImg = "";
    },

    // ai生成
    aiGenerate() {
      let obj = {
        style: "paster", // 风格 3d clay黏土  paster贴纸  repair修复 rembg 去背景 color上色  enlarge放大
        prompt: this.pasterVal,
        action: "generate",
        model: "llmf",
      };
      this.drawGenerate(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.paster_box {
  position: relative;

  .paster_img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .paster_empty {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 150px;

    img {
      width: 234px;
      height: 234px;
    }

    p {
      font-size: 16px;
      color: #333;
      line-height: 24px;
      text-align: center;
    }
  }
}
</style>
