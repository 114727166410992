import { getModulePermission, getDesignInfo } from '@/api/home'
const state = {
    modulePer: {},
    showPer: 1
}
const mutations = {
    SET_MODULEPER: (state, modulePer) => {
        state.modulePer = modulePer
    },
    SET_SHOWPER: (state, showPer) => {
        state.showPer = showPer
    },
}

const actions = {
    // get user info
    getOrgInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getModulePermission().then(response => {
                const { status, error_msg, data } = response
                if (status === false) {
                    reject(error_msg)
                }
                if (!data) {
                    reject('Verification failed, please Login again.')
                }
                if (data) {
                    commit('SET_MODULEPER', data);
                } else {
                    commit('SET_MODULEPER', { lib_learn_status: 0, lib_sc_status: 0 });

                }
                getDesignInfo().then(res => {
                    commit('SET_SHOWPER', res.data.show_windows);
                    resolve(data)
                })
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
