
import IndexView from '@/views/aiAsst/index.vue'
import agent from '@/views/aiAsst/agent/index.vue'
import creation from '@/views/aiAsst/creation/index.vue'
import toolkit from '@/views/aiAsst/creation/toolkit.vue'
import record from '@/views/aiAsst/creation/record.vue'



const aiAsstRouter = [
    {
        path: '/ai',
        name: 'Ai',
        meta: { title: '设计学习库-AI助手' },
        component: IndexView,
        redirect: '/ai/creation',
        children: [
            {
                path: 'agent/:id?',
                name: 'Agent',
                meta: { title: '设计学习库-智能应用' },
                component: agent
            },
            {
                path: 'creation',
                name: 'Creation',
                meta: { title: '设计学习库-AI创作' },
                component: creation
            },
            {
                path: 'toolkit/:type',
                name: 'Toolkit',
                meta: { title: '创作工具' },
                component: toolkit
            },
            {
                path: 'record',
                name: 'Record',
                meta: { title: '创作记录' },
                component: record
            },
        ]
    },
]

export default aiAsstRouter