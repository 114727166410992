<template>
  <div class="policy_wrap" :style="{ left: placeLeft }">
    <span class="policy_wrap_tips">以上内容均由AI生成,仅供参考和借鉴 </span>
    &nbsp;&nbsp;
    <a target="_blank" href="https://ai.yiihuu.com/agreement/">用户协议</a
    >&nbsp;|&nbsp;<a target="_blank" href="https://ai.yiihuu.com/privacypolicy/"
      >隐私政策</a
    >&nbsp;|&nbsp;<a target="_blank" href="https://ai.yiihuu.com">关于翼绘AI</a
    >&nbsp;备案信息:翼绘AI&nbsp;|&nbsp;Guangdong-YihuiAI-20240314&nbsp;&nbsp;
    <div>
      Copyright © 2011-2024 广州冠岳网络科技有限公司版权所有&nbsp;|&nbsp;<a
        target="_blank"
        href="http://beian.miit.gov.cn"
        >粤ICP备09217733号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyWrap",
  props: {
    placeLeft: {
      default: "50%",
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.policy_wrap {
  position: absolute;
  bottom: 10px;
  transform: translateX(-50%);
  text-align: center;
  font-size: 9px;
  color: #bdbdbf;
  line-height: 12px;
  white-space: nowrap;

  .policy_wrap_tips {
    color: #9da4bb;
  }

  a {
    transition: all 0.3s;
    color: #bdbdbf;
    text-decoration: unset;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }
}
</style>
