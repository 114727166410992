<template>
  <iframe :src="aiLink" class="agent_iframe" frameborder="0"></iframe>
</template>

<script>
import { getToken } from "@/utils/auth";
export default {
  name: "Agent",
  components: {},
  data() {
    return {
      aiLink: "",
    };
  },
  created() {
    document.domain = "yiihuu.com";
    this.aiLink = new URL(process.env.VUE_APP_AI_URL);
    this.aiLink.searchParams.set("platform", "lib");
    if (getToken()) this.aiLink.searchParams.set("token", getToken());
    if (this.$route.params.id) {
      this.aiLink.pathname = `/agent/${this.$route.params.id}`;
    } else {
      this.aiLink.pathname = `/agent/center`;
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.agent_iframe {
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>
